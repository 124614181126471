import { useState, useEffect } from 'react';
import { UseIsMobile } from './interfaces';

const useIsMobile: UseIsMobile = (breakpoint = 991) => {
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const resizeListener = () => {
        setIsMobile(window.innerWidth < breakpoint);
      };
      resizeListener();

      window.addEventListener('resize', resizeListener, { passive: true });
      return () => {
        window.removeEventListener('resize', resizeListener);
      };
    }
  }, [breakpoint]);

  return isMobile;
};

export default useIsMobile;
