import dynamic from 'next/dynamic';
import styles from './BackgroundVimeoVideo.module.scss';

type BackgroundVimeoVideoProps = Readonly<{ src: string }>;

const BackgroundVimeoVideo = ({ src }: BackgroundVimeoVideoProps) => {
  if (!src) {
    return null;
  }
  const VimeoPlayer = dynamic(() => import('../Video/VimeoPlayer'));

  return <VimeoPlayer className={styles.video} src={src} />;
};

export default BackgroundVimeoVideo;
