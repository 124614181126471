import { useRef } from 'react';
import type { DataLayerEvent } from '../useDataLayer/interfaces';
import useDataLayer from '../useDataLayer/useDataLayer';
import usePathname from '../usePathname/usePathname';
import { useSafeLayoutEffect } from '../useSafeLayoutEffect/useSafeLayoutEffect';

export const useInitialPageDataLayer = (
  events: DataLayerEvent[] = [{ event: 'pageView' }]
) => {
  const pathName = usePathname();
  const { pushDataLayerEvent } = useDataLayer();

  const lastPathNameRef = useRef('');

  useSafeLayoutEffect(() => {
    if (!lastPathNameRef.current || lastPathNameRef.current !== pathName) {
      events.forEach((event) => {
        pushDataLayerEvent(event);
      });
    }
    lastPathNameRef.current = pathName;
  }, [pushDataLayerEvent, pathName]);
};
