import { ProjectName } from '@/config';

export const logoFileName: Record<ProjectName, string> = {
  enercity: 'enercity.png',
  ecg: 'eCG.png',
  eeg: 'eEG.png',
  eng: 'eNG.png',
  esg: 'eSG.png',
  esol: 'eSOL.png',
  'mein-h-gas': 'mein_h_gas.png',
};
