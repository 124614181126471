import clsx from 'clsx';
import { useRef } from 'react';
import Background from '../Background/Background';
import Video from '../Video/Video';
import styles from './Section.module.scss';
import {
  SECTION_BACKGROUND_COLOR_MAPPER,
  SECTION_BACKGROUND_GRADIENT_MAPPER,
  SectionBackgroundColor,
  SectionVariant,
} from './consts';
import type { SectionProps } from './types';

const Section = ({
  children,
  backgroundGradientOverlay = false,
  bgColor = SectionBackgroundColor.Default,
  bgImageUrl,
  canSeeThrough = false,
  className,
  hasAspectRatioFallback = false,
  hasFullWidth = true,
  hasPadding = true,
  hasParallax = false,
  noSpaceBetween = false,
  overlayImageUrl,
  resetTopSpace = false,
  sectionId,
  skewedBottom,
  skewedTop,
  variant = SectionVariant.Default,
  withShadow = false,
  customBackground,
  vimeoUrl,
  videoName,
  hasBottomSpace = true,
  borderRadius,
  cornerStyle,
}: SectionProps): JSX.Element => {
  const sectionRef = useRef<HTMLDivElement>(null);

  const classes = clsx(
    styles.section,
    cornerStyle ? styles[cornerStyle] : null,
    {
      [styles.keyVisual]: variant === SectionVariant.KeyVisual,
      [styles.smallKeyVisual]: variant === SectionVariant.SmallKeyVisual,
      [styles.largePadding]: variant === SectionVariant.LargePadding,
      [styles.largePaddingBottom]:
        variant === SectionVariant.LargePaddingBottom,
      [styles.noSpaceBetween]: noSpaceBetween,
      [styles.skewedTop]: skewedTop,
      [styles.skewedBottom]: skewedBottom,
      [styles.parallax]: hasParallax,
      [styles.fullWidth]: hasFullWidth,
      [styles.withShadow]: withShadow,
      [styles.resetTopSpace]: resetTopSpace,
      [styles.hasBottomSpace]: hasBottomSpace,
      [styles.hasPadding]: hasPadding,
      [styles.hasAspectRatioFallback]: hasAspectRatioFallback,
      [styles.forceDoublePaddingBetween]:
        !!bgImageUrl || !!vimeoUrl || hasParallax || skewedTop || skewedBottom,
      [styles.hasBorderRadius]: !!cornerStyle,
    },
    className
  );

  const colorVariant = bgColor && SECTION_BACKGROUND_COLOR_MAPPER[bgColor];
  const gradientVariant =
    bgColor && SECTION_BACKGROUND_GRADIENT_MAPPER[bgColor];
  const isGradientOverlay =
    gradientVariant === 'default' ? backgroundGradientOverlay : true;

  const gradient = !!gradientVariant && (
    <Background.Gradient
      gradient={gradientVariant}
      isOverlay={isGradientOverlay}
    />
  );

  return (
    <section
      className={classes}
      data-bg-color={bgColor}
      style={
        borderRadius
          ? ({ '--section-border-radius': borderRadius } as React.CSSProperties)
          : {}
      }
      id={sectionId}
      ref={sectionRef}
    >
      {videoName && (
        <Video.VideoPlayerWithData
          videoName={videoName}
          className={styles.video}
          loop
          muted
          autoPlay
          playsInline
        />
      )}

      {!videoName && vimeoUrl && (
        <Video.VimeoPlayer className={styles.video} src={vimeoUrl} />
      )}

      {!canSeeThrough && (
        <>
          {!isGradientOverlay && gradient}

          {!!colorVariant && <Background.Color color={colorVariant} />}
          {!!bgImageUrl &&
            (hasParallax ? (
              <Background.Parallax>
                <Background.Picture asset={bgImageUrl} />
              </Background.Parallax>
            ) : (
              <Background.Picture asset={bgImageUrl} />
            ))}
          {!!overlayImageUrl && <Background.Image src={overlayImageUrl} />}

          {isGradientOverlay && gradient}

          {customBackground}
        </>
      )}

      {children}
    </section>
  );
};

export default Section;
