import clsx from 'clsx';
import styles from './BackgroundColor.module.scss';
import { BackgroundColorProps } from './types';

const BackgroundColor = ({
  className,
  color = 'surrogate',
  isOverlay = false,
}: BackgroundColorProps) => {
  return (
    <div
      className={clsx(
        styles.base,
        styles[color],
        { [styles.isOverlay]: isOverlay },
        className
      )}
    />
  );
};

export default BackgroundColor;
