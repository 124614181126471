import getBaseUrl from '@uikit/helpers/getBaseUrl';
import { isAbsoluteUrl } from 'next/dist/shared/lib/utils';

const getOgImagePath = (path: string) => {
  const baseUrl = getBaseUrl();

  return isAbsoluteUrl(path) ? path : `${baseUrl}${path}`;
};

export default getOgImagePath;
