import clsx from 'clsx';
import styles from './BackgroundGradient.module.scss';
import { BackgroundGradientProps } from './types';

const BackgroundGradient = ({
  className,
  gradient = 'default',
  isOverlay = false,
}: BackgroundGradientProps) => {
  return (
    <div
      className={clsx(
        styles.base,
        styles[gradient],
        { [styles.isOverlay]: isOverlay },
        className
      )}
    />
  );
};

export default BackgroundGradient;
