import React from 'react';
import styles from './BackgroundImage.module.scss';
import clsx from 'clsx';

const BackgroundImage = ({
  src,
  className,
  alt = 'Hintergrundbild',
}: {
  readonly src: string;
  className?: string;
  alt?: string;
}) => {
  return (
    <div className={clsx(styles.wrapper, className)}>
      <img className={styles.image} src={src} alt={alt} />
    </div>
  );
};

export default BackgroundImage;
