import BackgroundColor from './BackgroundColor';
import BackgroundGradient from './BackgroundGradient';
import BackgroundImage from './BackgroundImage';
import BackgroundParallax from './BackgroundParallax';
import BackgroundPicture from './BackgroundPicture';
import BackgroundSkew from './BackgroundSkew';
import BackgroundVimeoVideo from './BackgroundVimeoVideo';

const Background = {
  Color: BackgroundColor,
  Gradient: BackgroundGradient,
  Image: BackgroundImage,
  Parallax: BackgroundParallax,
  Picture: BackgroundPicture,
  Skew: BackgroundSkew,
  VimeoVideo: BackgroundVimeoVideo,
};

export default Background;
