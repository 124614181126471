export enum ContainerSize {
  Max = 'max',
  Default = 'default',
  Small = 'small',
  TinyPlus = 'tinyPlus',
  Tiny = 'tiny',
  Micro = 'micro',
}

export enum ContainerChildrenSpace {
  None = 'childrenSpaceNone',
  Micro = 'childrenSpaceMicro',
  Mini = 'childrenSpaceMini',
  Tiny = 'childrenSpaceTiny',
  Small = 'childrenSpaceSmall', // currently only for configurator
  Default = 'childrenSpaceDefault',
}
