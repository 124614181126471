import clsx from 'clsx';
import React from 'react';
import {
  BackgroundSkewColor,
  BackgroundSkewPosition,
  BackgroundSkewProps,
} from './BackgroundSkew.interfaces';
import styles from './BackgroundSkew.module.scss';

const BackgroundSkew = ({
  position = BackgroundSkewPosition.Bottom,
  color = BackgroundSkewColor.White,
}: BackgroundSkewProps) => (
  <div
    className={clsx({
      [styles.skewedTop]: position === BackgroundSkewPosition.Top,
      [styles.skewedBottom]: position === BackgroundSkewPosition.Bottom,
      [styles.skewBgWhite]: color === BackgroundSkewColor.White,
      [styles.skewBgGray]: color === BackgroundSkewColor.Gray,
    })}
  />
);

export default BackgroundSkew;
