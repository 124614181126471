import { PropsWithChildren } from 'react';
import { Parallax } from 'react-scroll-parallax';
import styles from './BackgroundParallax.module.scss';

const BackgroundParallax = ({ children }: PropsWithChildren) => {
  return (
    <Parallax className={styles.base} translateY={[-50, 50]}>
      {children}
    </Parallax>
  );
};

export default BackgroundParallax;
