import clsx from 'clsx';
import getStyleSize from '../../helpers/getStyleSize';
import { Align } from '../../types';
import styles from './Container.module.scss';
import { ContainerChildrenSpace, ContainerSize } from './consts';
import type { ContainerProps } from './interfaces';

const Container = ({
  size = ContainerSize.Default,
  children,
  flexCenter,
  offsetTop,
  className,
  spaceTop,
  spaceBottom,
  spaceLeft,
  spaceRight,
  align,
  spaceBetweenChildren = ContainerChildrenSpace.Default,
  ...additionalProps
}: ContainerProps): JSX.Element => {
  const classes = clsx(
    styles.container,
    styles[size],
    {
      [styles.alignCenter]: align === Align.Center,
      [styles.flexCenter]: flexCenter,
      [styles.offsetTop]: offsetTop,
    },
    styles[spaceBetweenChildren],
    className
  );

  const style = {
    ...(spaceLeft && { paddingLeft: getStyleSize(spaceLeft) }),
    ...(spaceRight && { paddingRight: getStyleSize(spaceRight) }),
    ...(spaceTop && { paddingTop: getStyleSize(spaceTop) }),
    ...(spaceBottom && { paddingBottom: getStyleSize(spaceBottom) }),
    ...additionalProps.style,
  };

  return (
    <div
      className={classes}
      {...additionalProps}
      style={style}
      data-is-container
    >
      {children}
    </div>
  );
};

export default Container;
