import {
  BackgroundColorProps,
  BackgroundGradientProps,
} from '../Background/types';

export enum SectionVariant {
  Default = 'default',
  KeyVisual = 'key-visual',
  LargePadding = 'large-padding',
  LargePaddingTop = 'large-padding-top',
  LargePaddingBottom = 'large-padding-bottom',
  SmallKeyVisual = 'small-key-visual',
}

export enum SectionBackgroundColor {
  Default = 'default',
  Gradient = 'gradient',
  Violet = 'violet',
  Blue = 'blue',
  Red = 'red',
  Green = 'green',
  Orange = 'orange',
  White = 'white',
  SurrogateLight = 'surrogate-light',
  GradientMagazine = 'gradient-magazine',
  GradientMeinHGas = 'gradient-mein-h-gas',
}

export enum SectionSkewBackground {
  White = 'white',
  Gray = 'gray',
  SurrogateLight = 'surrogate-light',
  Violet = 'violet',
  Blue = 'blue',
  Red = 'red',
  Green = 'green',
  Orange = 'orange',
}

export const SECTION_BACKGROUND_COLOR_MAPPER: Partial<
  Record<SectionBackgroundColor, BackgroundColorProps['color']>
> = {
  [SectionBackgroundColor.Blue]: 'blue',
  [SectionBackgroundColor.Green]: 'green',
  [SectionBackgroundColor.Orange]: 'orange',
  [SectionBackgroundColor.Red]: 'red',
  [SectionBackgroundColor.SurrogateLight]: 'surrogateLight',
  [SectionBackgroundColor.Violet]: 'violet',
  [SectionBackgroundColor.White]: 'white',
};

export const SECTION_BACKGROUND_GRADIENT_MAPPER: Partial<
  Record<SectionBackgroundColor, BackgroundGradientProps['gradient']>
> = {
  [SectionBackgroundColor.Gradient]: 'default',
  [SectionBackgroundColor.GradientMagazine]: 'magazine',
  [SectionBackgroundColor.GradientMeinHGas]: 'meinHGas',
};
