export enum BackgroundSkewPosition {
  Top = 'top',
  Bottom = 'bottom',
}

export enum BackgroundSkewColor {
  Gray = 'gray',
  White = 'white',
}

export interface BackgroundSkewProps {
  readonly position?: BackgroundSkewPosition;
  readonly color?: BackgroundSkewColor;
}
